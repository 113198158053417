import React, {FC, useEffect, useRef, useState, RefObject} from "react";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";
import departmentsAria from "../../services/departmentsArea";
import dynamic from 'next/dynamic'
import {ISlideImages, IState} from "../../types/state";
import {withHooksHOC} from "../../hooks/useWindowWidth";
import {setWindowSize} from "../../store/general";
import {LazyLoadImage} from "react-lazy-load-image-component";

const StroykaSlick = dynamic(() => import("../shared/StroykaSlick"))

interface IslickSettings {
    dots?: boolean,
    arrows?: boolean,
    infinite?: boolean,
    speed?: number,
    slidesToShow?: number,
    slidesToScroll?: number
}

interface IBlockSlideShowProps {
    media?: MediaQueryList,
    dbName: string,
    withDepartments?: boolean
    width?: number
}

const slickSettings: IslickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const BlockSlideShow: FC<IBlockSlideShowProps> = (props) => {
    // @ts-ignore
    const {dbName, withDepartments = false, width, sliderData} = props
    // const {dbName,withDepartments} = props

    const [media, setMedia] = useState<any>(null)
    const dispatch = useDispatch();
    const [isMobile, setIsMobile] = useState(false);
    let windowSize = useSelector((state: IState) => state.general.windowSize)
    const slideImages = sliderData.sliders
    const locale = useSelector((state: IState) => state.locale)

    let departmentsAreaRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (!windowSize) dispatch(setWindowSize(window.innerWidth))
        setIsMobile(windowSize <= 991)
    }, [windowSize])

    // useEffect(() => {
    //
    //     setMedia(window.matchMedia("(min-width: 992px)"));
    //
    //     if (media.addEventListener) {
    //         media.addEventListener("change", onChangeMedia);
    //     } else {
    //         media.addListener(onChangeMedia);
    //     }
    //
    //     return () => {
    //         departmentsAria.area = null;
    //
    //         if (!media.removeEventListener) {
    //             media.removeListener(onChangeMedia);
    //         } else {
    //             media.removeEventListener("change", onChangeMedia);
    //         }
    //     }
    //
    // }, [])

    // const onChangeMedia = () => {
    //
    //     if (media.matches) {
    //         departmentsAria.area = departmentsAreaRef;
    //     }
    //
    // }
    const setDepartmentsAreaRef = (ref: HTMLDivElement) => {
        // @ts-ignore
        departmentsAreaRef.current = ref;

        if (media.matches) {
            // @ts-ignore
            departmentsAria.area = departmentsAreaRef;
        }
    };

    const openNewTabClickBuyNow = (path: any) => {
        window.open(path, "_blank");
    }
    // let mobileSize = width <= 992
    // let desktopSize= width > 992
    const slideClasses = classNames("block-slideshow__slide-image", {
        "block-slideshow__slide-image--desktop": !isMobile,
        "block-slideshow__slide-image--mobile": isMobile,
    });

    const drawRightSlider = (path: string) => {
        return (
            <div className={slideClasses}>
                <LazyLoadImage
                    src={`/storage/${path}`}
                    alt="zega slide"
                    // placeholder="blur"
                    // layout="fill"
                    // priority
                    // blurDataURL={`/zega/blur.webp`}
                />
            </div>
        )
    }

    const slides = slideImages?.map((slide: ISlideImages, index: number) => {
        const image = slide.path; //(withDepartments ? slide.image_classic : slide.image_full);
        const MobileImage = slide.mobile_path;
        let realPath = !isMobile ? image : MobileImage
        /////FIXME: NEED CHECK SCREEN AFTER LOAD IMAGE

        return (
            // slide.locale.split(",").includes(locale.code) && (

                <div key={index} className="block-slideshow__slide">

                    {drawRightSlider(realPath)}

                    <div className="block-slideshow__slide-content">
                        <div
                            className="block-slideshow__slide-title"
                            dangerouslySetInnerHTML={{__html: slide.content}}
                        />

                        {/*<div*/}
                        {/*    className="block-slideshow__slide-text"*/}
                        {/*    dangerouslySetInnerHTML={{__html: slide.text}}*/}
                        {/*/>*/}
                    </div>

                    {slide.slider_path.length > 0 && (
                        <div className="block-slideshow__slide-button">
                            <button
                                onClick={() => openNewTabClickBuyNow(slide.slider_path)}
                                className="btn btn-orange slideshow-btn"
                            >
                                <FormattedMessage
                                    id="slideshow-btn"
                                    defaultMessage="Buy now"
                                />
                            </button>
                        </div>
                    )}
                </div>

            )
        // );
    });


    const blockClasses = classNames("block-slideshow", {
        "block-slideshow--layout--full": !withDepartments,
        "block-slideshow--layout--with-departments": withDepartments,
    });


    return (<>

        <div className={blockClasses}>
            <>
                {withDepartments && (
                    <div
                        className="absolute col-3 d-lg-block d-none"
                        ref={setDepartmentsAreaRef}
                    />
                )}
                <div className={`block-slideshow__body`}>
                    {/*// @ts-ignore*/}
                    <StroykaSlick {...slickSettings}>{slides}</StroykaSlick>
                </div>
            </>
        </div>
    </>)
}

export default withHooksHOC(BlockSlideShow)
